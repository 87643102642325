import React from 'react';

import './Footer.css';

function Footer() {
  return (
    <div className="footer">
      <p><b>Important Notice:</b> This chatbot provides automated responses. Conversations may be recorded for quality and improvement purposes. Please be aware that your interactions might be saved.</p>
    </div>
  );
}

export default Footer;
