import React, { Fragment } from 'react';
import Grid from '@mui/material/Grid';

import './App.css';
import Chat from '../chat/Chat.jsx';
import Footer from '../footer/Footer.jsx';

export default function App() {
  return (
    <Fragment>
      <div className="main-block" style={{ backgroundColor: 'white' }}>
        <Grid container>
          <Grid item md={2}></Grid>
          <Grid item xs={12} md={10} paddingLeft={1} paddingTop={1}>
            <h1/>
            <h1/>
            <Chat />
          </Grid>
        </Grid>
      </div>
      <Footer />
    </Fragment>
  );
}
